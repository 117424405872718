<template>
  <div class="news-card box-grid">
    <div class="box-flex">
      <div class="box-image">
        <slot name="image"></slot>
      </div>
      <div class="box-caption">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "news-card",
};
</script>

<style lang="scss">
.news-card {
  display: flex;

  .box-flex {
    position: relative;
    .box-caption {
      position: absolute;
      width: 100%;
      text-align: left;
      bottom: 0;
      left: 0;
      background-color: var(--background-color-semitransparent);
      color: var(--color-text-news);
      font-size: 0.8em;
      padding: 5px 0;
      min-height: 74px;

      .text {
        display: flex;
        flex-direction: column;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        padding: 0 25px;
        .news-time {
          font-weight: bold;
          // font-size: 9.3px;
          font-size: calc(var(--font-size--small) * 0.85);
          line-height: 13.5px;
          color: var(--color-text-title);
        }
        .news-title {
          font-weight: bold;
          // font-size: 17.4px;
          font-size: calc(var(--font-size--large) * 1.1);
          line-height: 26px;
          color: var(--color-news-title);
          padding: 5px 0;

          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .box-image {
      img {
        height: 423px;
        width: 423px;
        object-fit: cover;
      }
    }
  }
}

@media only screen and (max-width: 1224px) {
  .news-card {
    display: flex;

    .box-flex {
      position: relative;
      .box-caption {
        padding: 15px 0;
        .text {
          display: flex;
          padding: 0 20px;
          .news-time {
            font-weight: bold;
            // font-size: 6px;
            font-size: calc(var(--font-size--small) * 0.8);
            line-height: 12px;
            color: var(--color-text-news);
          }
          .news-title {
            font-weight: bold;
            // font-size: 12px;
            font-size: var(--font-size--small);
            line-height: 16px;
            color: var(--color-news-title);
            padding: 0;
          }
        }
      }

      .box-image {
        img {
          height: 310px;
          width: 310px;
          object-fit: cover;
        }
      }
    }
  }
}
</style>
