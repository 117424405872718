<template>
  <div class="news">
    <div class="card-area" v-loading="loading">
      <div
        class="card-news"
        v-for="noticia in noticias.data"
        :key="noticia.id"
        @click.prevent="getPage(noticia.id, slugify(noticia.titulo))"
      >
        <NewsCard image title>
          <img
            :src="(noticia.capa.length && noticia.capa) || ''"
            alt=""
            slot="image"
          />
          <div class="text" slot="body">
            <span class="news-time">{{ noticia.data | formatDate("LL") }}</span>
            <span class="news-title">{{ noticia.titulo }}</span>
          </div>
        </NewsCard>
      </div>
    </div>
    <div v-if="Object.keys(noticias).length > 0">
      <b-pagination
        align="center"
        v-model="noticias.current_page"
        pills
        :total-rows="noticias.total"
        @change="handleChangePage"
        :per-page="noticias.per_page"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import NewsCard from "@/components/News/NewsCards/NewsCard.vue";

export default {
  name: "news",
  components: { NewsCard },
  data() {
    return {
      noticias: [],
      loading: false,
    };
  },
  methods: {
    async getNoticias(pg = 1) {
      this.loading = true;
      this.noticias = [];
      const res = await this.$axios.get(`noticias?page=${pg}`).catch((e) => {
        console.error("Noticias", e);
      });
      if (res.status === 200) {
        this.noticias = res.data;
      }
      this.loading = false;
    },
    handleChangePage(pg) {
      this.getNoticias(pg);
    },
    slugify(text) {
      return text
        .toString()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .trim()
        .replace(/\s+/g, "-")
        .replace(/[^\w-]+/g, "")
        .replace(/--+/g, "-");
    },
    getPage(id, title) {
      this.$router.push(`${`/noticia/${id}/${title}`}`);
    },
    showDestaque(noticia) {
      this.getPage(noticia.id, this.slugify(noticia.titulo));
    },
  },
  mounted() {
    this.getNoticias();
  },
};
</script>

<style lang="scss" scoped>
.news {
  padding: 0;
  background-color: var(--background-color-primary);
  overflow: hidden;

  .card-area {
    margin: 30px 10px 30px 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    .card-news {
      padding: 0 20px 20px 0;
      cursor: pointer;
    }
  }
}
</style>
